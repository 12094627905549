.ant-picker {
  width: 32%  !important;
  border: 1px solid #727483;
  border-radius: 10px;
  padding: 9px 11px 9px;
}

.ant-picker .ant-picker-separator {
  margin-top: -5px;
}

input::placeholder {
  color: rgb(58, 58, 58) !important;
  font-size: 15px;
}

.ant-picker .ant-picker-input > input {
  font-size: 16px !important;
}



.inputDeprturFlight .ant-select-selector::before {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='17' viewBox='0 0 20 17' fill='%23808080'%3E%3Cpath d='M1.37578 16.4977V15.4977H18.3758V16.4977H1.37578ZM2.95078 11.4227L0.675781 7.49766L1.52578 7.29766L3.32578 8.84766L8.72578 7.39766L4.67578 0.547657L5.75078 0.222656L12.6008 6.34766L17.8258 4.94766C18.2091 4.84766 18.5551 4.91832 18.8638 5.15966C19.1718 5.40166 19.3258 5.71432 19.3258 6.09766C19.3258 6.36432 19.2508 6.59766 19.1008 6.79766C18.9508 6.99766 18.7508 7.13099 18.5008 7.19766L2.95078 11.4227Z'%3E%3C/path%3E%3C/svg%3E");
  position: absolute;
  left: 12px;
  top: 56%;
  transform: translateY(-50%);
}
.inputArrivalFlight .ant-select-selector::before {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23808080' class='icon-2'%3E%3Cmask id='mask0_160_1644' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24' style='mask-type: alpha;'%3E%3Crect width='24' height='24' fill='%23D9D9D9'%3E%3C/rect%3E%3C/mask%3E%3Cg mask='url(%23mask0_160_1644)'%3E%3Cpath d='M3.5 20.5004V19.5004H20.5V20.5004H3.5ZM18.975 15.4004L3.5 11.1254V6.70039L4.3 6.92539L5 9.05039L10.5 10.6004V2.65039L11.625 2.92539L14.375 11.6754L19.625 13.1504C19.8917 13.2171 20.1043 13.3587 20.263 13.5754C20.421 13.7921 20.5 14.0337 20.5 14.3004C20.5 14.6837 20.3377 14.9921 20.013 15.2254C19.6877 15.4587 19.3417 15.5171 18.975 15.4004Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  left: 12px;
  top: 56%;
  transform: translateY(-50%);
}
 .ant-select-selector
{
  padding-left: 42px !important;
  padding-top: 7px !important;
}
.ant-select-selection-item
{
  margin-top: 2px !important;
  font-size: 18px;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  padding-left: 30px !important;
  font-size: 16px !important;
  padding-top: 15px;
  margin-top: 2px;
  color: rgb(92, 92, 92);
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: rgb(92, 92, 92);
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 48px !important;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border: 1px solid #727483;
  border-radius: 10px;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border: 1px solid #727483;
  border-radius: 10px;
}

.ant-select .ant-select-arrow {
  display: none;
} 

/* ----------------Media Query for Search Engine */

@media only screen and (max-width: 768px){
  .ant-picker {
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  /* :where(.css-dev-only-do-not-override-1wazalj).ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display:block !important;  
    flex-direction: column !important;
    text-align: center !important;

  } */
   .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
    display: block !important;
    flex-direction: column !important;
    text-align: center !important;
}
  /* :where(.css-dev-only-do-not-override-1wazalj).ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
    padding: 8px 58px !important;
} */


}

/* --------------------------User Contact Detail----------------------- */

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  padding-top: 8.5px !important; 
  padding-bottom: 8.5px !important; 
  padding-left: 14px !important; 
  padding-right:38px !important;
}